const [SHOP_TYPE_AMAZON, SHOP_TYPE_ALIBABA_EXPRESS, SHOP_TYPE_EBAY, SHOP_TYPE_OTHER] = [2, 1, 3, 4]
import { checkPermission } from '@/utils'
export const list = [
  {
    label: '亚马逊 ',
    value: SHOP_TYPE_AMAZON,
    logoUrl: '',
    // permission: checkPermission('factory:order:orderSearch'),
    permission: true,
    childPermission: {}
  },
  {
    label: '速卖通',
    logoUrl: '',
    value: SHOP_TYPE_ALIBABA_EXPRESS,
    permission: [],
    childPermission: {}
  },
  {
    label: 'ebay',
    value: SHOP_TYPE_EBAY,
    logoUrl: '',
    permission: [],
    childPermission: {}
  },
  {
    label: '其他',
    value: SHOP_TYPE_OTHER,
    permission: [],
    childPermission: {}
  }
]

// 亚马逊洲
export const alibabaContinents = ['亚洲', '欧洲', '美洲']

// 亚马逊国家
export const alibabaCountrys = [
  '美国',
  '加拿大',
  '墨西哥',
  '英国',
  '法国',
  '德国',
  '意大利',
  '荷兰',
  '西班牙',
  '瑞典',
  '土耳其',
  '波兰',
  '日本'
]
