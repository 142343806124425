<template>
  <BaseDialog ref="baseDialog" width="592px" :dialogVisible.sync="dialog" :title="title">
    <template>
      <BaseForm
        v-loading="loading"
        ref="form"
        :label-width="baseWidth"
        :cols="formField"
        :form="form"
        size="small"
        :formVisible="dialog"
      >
        <template #websiteAreaCodeSlot="{ scoped: { prop } }">
          <AvueCrudSelect class="websiteArea" size="small" :dic="websiteAreaDic" v-model="form[prop]"></AvueCrudSelect>
          <!-- <AvueCrudCheckbox :dic="websiteAreaSiteDic" v-model="form['countryIdList']"></AvueCrudCheckbox> -->
        </template>

        <template #countryIdListSlot="{ scoped: { prop } }" v-if="form['websiteAreaCode']">
          <!-- <AvueCrudCheckbox class="countryIdList" :dic="websiteAreaSiteDic" v-model="form[prop]"></AvueCrudCheckbox> -->
          <radio class="countryIdList" v-model="form[prop]" :dic="websiteAreaSiteDic"></radio>
        </template>

        <template #isAutoSyncOrderSlot="{ scoped: { prop } }">
          <div>
            <el-switch v-model="form[prop]" active-color="#3841DB" inactive-color="#dcdfe6"> </el-switch>
            <radio v-if="form[prop]" v-model="form['autoSyncOrderType']" :dic="autoSyncOrderDic"></radio>
          </div>
        </template>
        <template #isAutoSyncWaybillSlot="{ scoped: { prop } }">
          <div>
            <el-switch v-model="form[prop]" active-color="#3841DB" inactive-color="#dcdfe6"> </el-switch>
            <radio v-if="form[prop]" v-model="form['autoSyncWaybillType']" :dic="autoSyncWaybillDic"></radio>
          </div>
        </template>
      </BaseForm>
    </template>
    <template #footer>
      <LoadingBtn size="small" @click="cancel"> 取消 </LoadingBtn>
      <LoadingBtn size="small" type="primary" @click="doSubmit"> 保存 </LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import formField from './field'
import { createShop as add } from '@/api/shop/manageApi'
import { commonFromMixin } from '@/mixins'
import { getDiffData, deepClone } from '@/components/avue/utils/util'
import { mapGetters, mapActions } from 'vuex'
import { autoSyncOrderDic, autoSyncWaybillDic } from './const'
import radio from '../radio'
import { alibabaContinents, alibabaCountrys } from '../../const'
import { validatenull } from '@/components/avue/utils/validate'

export default {
  mixins: [commonFromMixin],
  components: { radio },
  props: {
    initData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formField: deepClone(formField),
      form: {},
      baseWidth: '90px',
      loading: false,
      AreaSiteDic: [],
      websiteAreaSiteDic: [],
      autoSyncOrderDic,
      autoSyncWaybillDic
    }
  },
  computed: {
    ...mapGetters(['continentDictCode', 'shop']),
    websiteAreaDic() {
      return this.continentDictCode
        .map((item) => {
          return {
            label: item.itemName,
            value: item.id
          }
        })
        .filter((item) => alibabaContinents.indexOf(item.label) > -1)
    },
    calcWebsiteAreaSiteDic() {
      return this.shop.websites
    }
  },
  watch: {
    'form.countryIdList': {
      async handler(n, o) {
        if ((!n?.length && !o?.length) || o == '' || !o) {
          return
        }

        try {
          await this.$refs['form'].validate()
        } catch {}
      },
      immediate: true,
      deep: true
    },
    'form.websiteAreaCode': {
      handler(n, o) {
        let dicData = []
        this.$set(this.form, 'countryIdList', [])
        let countryIdListIdx = this.formField.findIndex((item) => item.name === 'countryIdList')

        if (validatenull(n)) {
          this.formField[countryIdListIdx].rule = []
          this.formField[countryIdListIdx].hidden = true
        } else {
          this.formField[countryIdListIdx].hidden = false
          this.formField[countryIdListIdx].rule = [
            {
              required: true,
              trigger: 'change',
              message: '请选择一个站点'
            }
          ]
        }
        let data = this.calcWebsiteAreaSiteDic.filter((item) => item.continentDictCode === n)
        if (!data || data.length == 0) {
          return
        }
        dicData = data
          .map((item) => {
            return {
              label: item.countryCnName,
              value: item.id
            }
          })
          .filter((item) => alibabaCountrys.indexOf(item.label) > -1)

        this.$nextTick(() => {
          this.websiteAreaSiteDic = deepClone(dicData)
        })
      },
      immediate: true,
      deep: true
    },
    'form.isAutoSyncOrder': {
      handler(n, o) {
        if (n) {
          this.form['autoSyncOrderType'] = 1
        }
      },
      immediate: true,
      deep: true
    },
    'form.isAutoSyncWaybill': {
      handler(n, o) {
        if (n) {
          this.form['autoSyncWaybillType'] = 1
        }
      },
      immediate: true,
      deep: true
    },
    'initData.shopType': {
      handler(n, o) {
        let websiteAreaCodeIdx = this.formField.findIndex((item) => item.name === 'websiteAreaCode')
        let countryIdListIdx = this.formField.findIndex((item) => item.name === 'countryIdList')

        let isAutoSyncOrderIdx = this.formField.findIndex((item) => item.name === 'isAutoSyncOrder')
        let isAutoSyncWaybillIdx = this.formField.findIndex((item) => item.name === 'isAutoSyncWaybill')
        if (n == 2) {
          this.formField[websiteAreaCodeIdx].hidden = false
          this.formField[countryIdListIdx].hidden = false
          this.baseWidth = '100px'
        }
        if (n == 1) {
          this.formField[websiteAreaCodeIdx].rule = []

          this.formField[websiteAreaCodeIdx].hidden = true
          this.formField[countryIdListIdx].hidden = true

          this.formField[isAutoSyncOrderIdx].hidden = false
          this.formField[isAutoSyncWaybillIdx].hidden = false

          this.baseWidth = '120px'
        }
        if (n >= 3) {
          this.formField[websiteAreaCodeIdx].hidden = true
          this.formField[countryIdListIdx].hidden = true

          this.formField[isAutoSyncOrderIdx].hidden = true
          this.formField[isAutoSyncWaybillIdx].hidden = true
          this.formField[websiteAreaCodeIdx].rule = []
          this.formField[countryIdListIdx].rule = []
          this.baseWidth = '90px'
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async doSubmit() {
      try {
        await this.$refs['form'].validate()
      } catch {
        return false
      }

      this.loading = true
      let params = deepClone(Object.assign(this.form, this.initData))

      if (this.initData.shopType == 2) {
        params['websiteAreaCode'] = JSON.stringify({
          continentDictCode: params['websiteAreaCode'],
          countryIdList: [params['countryIdList']]
        })
        delete params['countryIdList']
      }

      if (this.initData.shopType == 1) {
        params['isAutoSyncOrder'] = params['isAutoSyncOrder'] ? 1 : 0
        params['isAutoSyncWaybill'] = params['isAutoSyncWaybill'] ? 1 : 0
        delete params['countryIdList']
      }

      const [err, res] = await awaitWrap(add(params))
      if ($SUC(res)) {
        this.success()
        this.sup_this && this.sup_this.init()
      }
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .dialog-body {
  max-height: 70vh;
}

::v-deep .el-radio {
  padding-top: 2px;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .tableForm .el-form {
  padding: 1rem 10px;
}
::v-deep .el-form-item__label {
  height: 32px;
  line-height: 32px;
}
::v-deep input {
  width: 296px;
  height: 32px;
  line-height: 32px;
}
::v-deep .el-input--suffix {
  width: 296px;
}
.websiteArea {
  width: 296px;
}
::v-deep .el-switch {
  padding-top: 6px;
}
::v-deep .el-switch__core {
  width: 28px !important;
  height: 16px;
  margin-top: 4px;
}
::v-deep .el-switch__core:after {
  top: 0px;
  width: 14px;
  height: 14px;
}

::v-deep .el-switch.is-checked .el-switch__core::after {
  margin-left: -14px;
}
::v-deep .el-form-item__content {
  line-height: 20px;
}

::v-deep .el-radio {
  margin-top: 10px;
}
::v-deep .el-checkbox__label {
  height: 14px;
  line-height: 14px;
}
::v-deep .el-checkbox__inner {
  height: 18px;
  width: 18px;
}
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 4px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 5px;
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}
.countryIdList {
  padding-left: 100px;
  margin-top: -20px;
}
::v-deep .countryIdList + .el-form-item__error {
  padding-left: 100px;
  padding-top: 10px;
}
</style>
