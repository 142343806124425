<template>
  <div class="store-radio">
    <el-radio-group v-model="text" @change="handleChange" :disabled="disabled" :size="size">
      <el-radio v-for="(item, index) in dic" :label="item[dictValue] || item.value" :key="index" :border="border">
        {{ item[dictLabel] || item.label }}
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
import { validatenull } from '@/components/avue/utils/validate'
export default {
  props: {
    value: {
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dic: {
      type: Array,
      default: () => {
        return []
      }
    },
    props: {
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      text: ''
    }
  },
  computed: {
    dictLabel() {
      return this.props.label || 'label'
    },
    dictValue() {
      return this.props.value || 'value'
    }
  },
  watch: {
    value: {
      handler() {
        this.text = this.value
      },
      immediate: true
    },
    // 第一次点击，rules校验提示，第二次才消失
    text: {
      handler(n) {
        if (validatenull(this.value)) {
          this.$emit('input', n)
          this.$emit('change', n)
        }
      },
      immediate: true
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value)
      this.$emit('search-change')
    }
  }
}
</script>

<style lang="scss">
.store-radio {
  .el-radio__input.is-checked {
    width: 16px;
    height: 16px;
    border-color: #3841db;
    border: 3px;
    box-shadow: 0 0px 3px 0px #3841db;
    border-radius: 50%;
  }
  .el-radio__inner::after {
    width: 8px;
    height: 8px;
    background-color: #3841db;
  }
  .el-radio__inner,
  .el-radio__input {
    width: 16px;
    height: 16px;
    background-color: #fff !important;
  }
  .el-radio__original {
    width: 16px;
    height: 16px;
  }
  .el-radio {
    margin-top: 16px !important;
    height: 16px;
    line-height: 16px;
  }

  .el-radio__label {
    font-size: 14px;
    color: #636c78;
    height: 16px;
    line-height: 16px;
  }
  .el-radio__input.is-checked + .el-radio__label {
    height: 16px;
    line-height: 16px;
  }
}
</style>
