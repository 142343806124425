export const autoSyncOrderDic = [
  {
    label: '同步所有产品订单',
    value: 1
  },
  {
    label: '仅同步平台定制产品订单',
    value: 2
  }
]

export const autoSyncWaybillDic = [
  {
    label: '按照速卖通发货时间同步运单号',
    value: 1
  },
  {
    label: '运单号生成后立即同步运单号',
    value: 2
  },
  {
    label: '产品发出时同步运单号',
    value: 3
  }
]
