var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDialog',{ref:"baseDialog",attrs:{"width":"592px","dialogVisible":_vm.dialog,"title":_vm.title},on:{"update:dialogVisible":function($event){_vm.dialog=$event},"update:dialog-visible":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('LoadingBtn',{attrs:{"size":"small"},on:{"click":_vm.cancel}},[_vm._v(" 取消 ")]),_c('LoadingBtn',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.doSubmit}},[_vm._v(" 保存 ")])]},proxy:true}])},[[_c('BaseForm',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"form",attrs:{"label-width":_vm.baseWidth,"cols":_vm.formField,"form":_vm.form,"size":"small","formVisible":_vm.dialog},scopedSlots:_vm._u([{key:"websiteAreaCodeSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('AvueCrudSelect',{staticClass:"websiteArea",attrs:{"size":"small","dic":_vm.websiteAreaDic},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}})]}},(_vm.form['websiteAreaCode'])?{key:"countryIdListSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('radio',{staticClass:"countryIdList",attrs:{"dic":_vm.websiteAreaSiteDic},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}})]}}:null,{key:"isAutoSyncOrderSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('div',[_c('el-switch',{attrs:{"active-color":"#3841DB","inactive-color":"#dcdfe6"},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}}),(_vm.form[prop])?_c('radio',{attrs:{"dic":_vm.autoSyncOrderDic},model:{value:(_vm.form['autoSyncOrderType']),callback:function ($$v) {_vm.$set(_vm.form, 'autoSyncOrderType', $$v)},expression:"form['autoSyncOrderType']"}}):_vm._e()],1)]}},{key:"isAutoSyncWaybillSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('div',[_c('el-switch',{attrs:{"active-color":"#3841DB","inactive-color":"#dcdfe6"},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}}),(_vm.form[prop])?_c('radio',{attrs:{"dic":_vm.autoSyncWaybillDic},model:{value:(_vm.form['autoSyncWaybillType']),callback:function ($$v) {_vm.$set(_vm.form, 'autoSyncWaybillType', $$v)},expression:"form['autoSyncWaybillType']"}}):_vm._e()],1)]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }