import { requireFun } from '@/utils'
import { isW } from '@/utils/validate'

export default [
  {
    label: '店铺名称',
    prop: 'shopName',
    name: 'shopName',
    colNum: 17,
    placeholder: '请输入店铺名称',
    rule: [
      {
        required: true,
        message: requireFun('店铺名称')
      }
    ]
  },
  {
    label: '店铺编码',
    prop: 'shopCode',
    name: 'shopCode',
    colNum: 17,
    placeholder: '请输入店铺编码',
    rule: [
      {
        required: true,
        message: requireFun('店铺编码')
      },
      {
        validator: isW
      }
    ]
  },
  {
    hidden: true,
    label: '亚马逊站点',
    prop: 'websiteAreaCode',
    name: 'websiteAreaCode',
    colNum: 17,
    placeholder: '请选择亚马逊站点',
    slotName: 'websiteAreaCodeSlot',
    rule: [
      {
        required: true,
        message: requireFun('亚马逊站点')
      }
    ]
  },
  {
    hidden: false,
    label: '',
    prop: 'countryIdList',
    name: 'countryIdList',
    slotName: 'countryIdListSlot',
    colNum: 17,
    rule: [
      {
        required: false,
        trigger: 'change',
        message: requireFun('亚马逊站点')
      }
    ]
  },
  {
    hidden: true,
    label: '自动同步订单',
    prop: 'isAutoSyncOrder',
    name: 'isAutoSyncOrder',
    colNum: 17,
    placeholder: '请选择',
    slotName: 'isAutoSyncOrderSlot',
    rule: [
      {
        required: false,
        message: requireFun('自动同步订单')
      }
    ]
  },
  {
    hidden: true,
    label: '自动同步运单号',
    prop: 'isAutoSyncWaybill',
    name: 'isAutoSyncWaybill',
    colNum: 17,
    placeholder: '请选择',
    slotName: 'isAutoSyncWaybillSlot',
    rule: [
      {
        required: false,
        message: requireFun('自动同步运单号')
      }
    ]
  }
]
